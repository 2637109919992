import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Guarantee.module.scss"
import { Link } from "gatsby"

const Guarantee = ({ strapiData, strapiData1 }) => {
  return (
    <div className={styles.maindiv}>
    <Container>
    <div className={styles.progressSec}>
    
        <div className={styles.progress}>
          <h2 className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />
          <p className={styles.subDescription}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row >
            {strapiData?.cards?.map((item, index) => (
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <h3
                    className={`${styles.subHeading} ${index === 0 ? styles.black : styles[item.textColor]
                      }`}
                    dangerouslySetInnerHTML={{
                      __html: item?.title,
                    }}
                  >
                  </h3>
                  <p
                    className={`${styles.description} ${index === 1 ? styles.white : styles.black
                      }`}
                  >
                    {item?.subTitle}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div
          className={`${styles.concerns}
           ${styles.customScale} 
         
          `}
        >
          <Row className="justify-content-between align-items-center">
            <div>
              <p className={`${styles.concernsHead}`}>
                {strapiData1?.title}
              </p>
              <p className={`${styles.concernsDesc}`}>
                {strapiData1?.subTitle}
              </p>
            </div>

            <div className={styles.cardFooter}>
              <Link
                to={"/contact-us/"}
                className="newBtn"
                style={{ color: "white" }}
              >
                {strapiData1?.buttons?.[0]?.title}
              </Link>
            </div>
          </Row>
        </div>
     
    </div>
    </Container>
    </div>
  )
}

export default Guarantee
